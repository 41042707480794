<template>
  <div class="city">
    <Header isHideLeft> 华夏元宇宙 </Header>
    <div
      class="banner"
      @click="
        isShowView = !isShowView;
        scale = 1;
      "
    >
      <img class="img" :src="info.map_url" alt="" />
      <img class="open" src="../../assets/images/open.png" alt="" />
    </div>
    <div class="line">
      <div class="left">
        <img src="../../assets/images/shijiebei/city_icon.png" alt="" />
      </div>
      <div class="middle">试玩展示</div>
      <div class="right">
        <img src="../../assets/images/shijiebei/city_icon.png" alt="" />
      </div>
    </div>
    <div class="video">
      <video :src="info.video" ref="video" controls="controls" />
      <div
        class="videoBox"
        :style="{ zIndex: isPlayVideo ? 8 : 10 }"
        v-if="info.cover"
      >
        <img class="bg" :src="info.cover" alt="" />
        <div class="play" @click="playVideo">
          <img src="../../assets/images/bofang.png" alt="" />
        </div>
      </div>
    </div>
    <div class="line">
      <div class="left">
        <img src="../../assets/images/shijiebei/city_icon.png" alt="" />
      </div>
      <div class="middle">华夏元宇宙简介</div>
      <div class="right">
        <img src="../../assets/images/shijiebei/city_icon.png" alt="" />
      </div>
    </div>
    <div class="introduc" style="white-space: pre-line">
      {{ info.content }}
    </div>
    <div
      class="box"
      v-show="isShowView"
      @click="
        isShowView = !isShowView;
        scale = 1;
      "
    >
      <img
        :src="info.map_url"
        ref="pinch"
        :style="{
          transform: `scale(${scale}) translate(${translateX}px,${translateY}px)`,
        }"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import AlloyFinger from 'alloyfinger';
export default {
  name: 'city',
  data() {
    return {
      info: {},
      isPlayVideo: false,
      scale: 1,
      translateX: 0,
      translateY: 0,
      isShowView: false,
    };
  },
  mounted() {
    this.$api.metaInfo().then((e) => {
      if (e.code === 0) {
        this.info = e.data;
      } else {
        this.$toast(e.msg);
      }
    });
    let initScale = 1;
    new AlloyFinger(this.$refs.pinch, {
      multipointStart: () => {
        initScale = this.scale;
      },
      pinch: (evt) => {
        this.scale = initScale * evt.zoom;
      },
      pressMove: (evt) => {
        this.translateX += evt.deltaX;
        this.translateY += evt.deltaY;
        evt.preventDefault();
      },
    });
  },
  methods: {
    playVideo() {
      this.$refs.video.play();
      this.isPlayVideo = true;
    },
  },
};
</script>

<style lang="less" scoped>
img {
  vertical-align: middle;
}
.city {
  padding-bottom: 60px;
}
.line {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #010000;
  font-size: 15px;
  margin: 20px 0 15px;
  img {
    vertical-align: middle;
    width: 10px;
  }
  .middle {
    margin: 0 5px;
  }
}
.introduc {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  padding: 20px 10px 15px;
  background: #ffffff;
  box-shadow: -4px 0px 11px 2px rgba(147, 188, 254, 0.14);
  border-radius: 10px;
  margin: 15px;
}
.banner {
  position: relative;
  .open {
    position: absolute;
    right: 18px;
    bottom: 12px;
    width: 15px;
  }
  .img {
    max-width: 100%;
  }
}
.video {
  position: relative;
  height: 170px;
  .videoBox {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
  }
  img.bg {
    position: relative;
    z-index: 9;
    width: 100%;
    height: 100%;
  }
  .play {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    // background: rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    img {
      width: 30px;
      background: transparent;
      margin-left: 5px;
    }
  }
  video {
    width: 100%;
    vertical-align: middle;
    background-color: black;
    z-index: 10;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    object-fit: cover;
  }
}
.box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-self: center;
  z-index: 9999;
  img {
    width: 100%;
  }
}
</style>
